#root {
  display: flex;
  flex-direction: column;
}

body {
  margin: 0;
  font-family: Helvetica,Verdana,Arial,sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: rgb(255, 255, 255);
  background-color: #171717;
  -webkit-overflow-scrolling: touch;
  overflow-x: hidden;
}


input{
  font-family: Helvetica,Verdana,Arial,sans-serif;
  display: flex;
  background-color: rgb(255, 255, 255);
  padding: 8px;
  font-size: 15px;
  font-weight: bold;
  border-width: 0;
  background-color: transparent;
  color: #ffffff;
  border-style: none;
  border-radius: 5px;
  cursor: pointer;
  transition: 0.2s;
  width: 100%;
}
/* input hover */
input:hover{
  background-color: #202020;
}
.react-datepicker__month-container{
  background-color: #333;
  color: #fff !important;
}
.react-datepicker__day:hover{
  color: white !important; 
}

textarea {
  font-family: Helvetica,Verdana,Arial,sans-serif;
}

.react-datepicker__current-month, .react-datepicker-time__header, .react-datepicker-year-header{
  color: #fff !important;
}

.react-datepicker__day-name,
.react-datepicker__day,
.react-datepicker__time-name {
  color: #fff !important;
  
}
.react-datepicker__day:hover,
.react-datepicker__month-text:hover,
.react-datepicker__quarter-text:hover,
.react-datepicker__year-text:hover {
  border-radius: 1rem !important;
  background-color: #8f8225 !important;
}


.react-datepicker__day:hover {
  color: #333 !important;
}
.react-datepicker__day--keyboard-selected, .react-datepicker__month-text--keyboard-selected, .react-datepicker__quarter-text--keyboard-selected, .react-datepicker__year-text--keyboard-selected{
  background-color: #e7d338 !important;
  border-radius: 1rem !important;
  color: #333 !important;
}
.react-datepicker__day--selected{
  background-color: #e7d338 !important;
  border-radius: 1rem !important;
  color: #333 !important;
}

.react-datepicker__header{
  background-color: #333 !important;
}
.react-datepicker__day--outside-month{
  color: rgb(77, 77, 77) !important;
}
